import { createStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    orderCode: {
      padding: theme.spacing(1),
      background: theme.palette.success.main,
      width: 'fit-content',
      color: '#fff',
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      borderRadius: 10,
      marginTop: theme.spacing(1),
      '& h6': {
        fontWeight: 600
      }
    },
    orderStatus: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      marginTop: theme.spacing(1),
      width: 'fit-content',
      color: '#fff',
      borderRadius: 10
    },
    wfpmStatus: {
      background: theme.palette.warning.light
    },
    paidStatus: {
      background: theme.palette.success.light
    },
    card: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      cursor: 'pointer'
    },
    centerVerticalGrid: {
      display: 'flex',
      alignItems: 'center'
    },
    transferInfoCard: {
      backgroundColor: '#24ACD9'
    },
    paymentMethodInfo: {
      marginTop: theme.spacing(3)
    },
    whiteText: {
      color: 'white'
    },
    transferredButton: {
      backgroundColor: 'white'
    },
    disabledTransferredButton: {
      backgroundColor: 'white'
    },
    checkIcon: {
      color: 'green'
    },
    transferredText: {
      color: 'white',
      marginLeft: theme.spacing(1)
    },
    dashedBorderCard: {
      borderStyle: 'dashed',
      borderColor: '#BDBDBD'
    },
    transferredButtonGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })

export default styles
