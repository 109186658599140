import { IRoute } from 'interfaces'
import {
  AddToCart,
  Cart,
  Home,
  Login,
  Checkout,
  VNPAYPaymentResult,
} from 'views'
import PaymentSuccess from 'views/PaymentSuccess'

const routes: IRoute[] = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/micro/addToCart', component: AddToCart },
  { path: '/micro/cart', component: Cart },
  { path: '/micro/checkout', component: Checkout },
  { path: '/micro/vnpay-payment-result', component: VNPAYPaymentResult },
  { path: '/micro/payment/success', component: PaymentSuccess }
]

export default routes
