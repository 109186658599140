import React from 'react'
import './App.css'

import { useAnalytics, useFirebaseApp } from 'reactfire'

import { preloadSDKs } from 'utilities/firebaseUtilities'
import { history } from 'providers/router/BrowserHistory'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import PaymentTheme from 'themes/Payment'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorBlock from 'components/ErrorBlock'
import { Router } from 'react-router-dom'
import RouterView from 'components/RouterView'
import { RouteContextProvider } from 'contexts'
import routing from 'routing'

const PageViewLogger: React.FC = () => {
  const analytics = useAnalytics()
  const { location } = history

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      analytics.logEvent('page-view', { path_name: location.pathname })
    }
  }, [location.pathname, analytics])

  return null
}

function App() {
  const firebaseApp = useFirebaseApp()
  preloadSDKs(firebaseApp)

  return (
    <ThemeProvider theme={PaymentTheme}>
      <CssBaseline />
      <ErrorBoundary fallback={ErrorBlock}>
        <Router history={history}>
          <RouteContextProvider routes={routing}>
            <RouterView></RouterView>
          </RouteContextProvider>
        </Router>
        <PageViewLogger />
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
