import firebase from 'firebase'
import {
  preloadAuth,
  preloadFirestore,
  preloadFunctions,
  preloadStorage
} from 'reactfire'

export const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadFirestore({
      firebaseApp: firebaseApp,
      setup: async (firestore) =>
        // firestore().settings({ host: 'localhost:8080', ssl: false })
        {
          await firestore().clearPersistence()
          firestore().enablePersistence({ synchronizeTabs: false })
        }
    }),
    preloadAuth({
      firebaseApp: firebaseApp,
      setup: (auth) =>
        auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }),
    preloadStorage({
      firebaseApp: firebaseApp,
      setup: (storage) => storage().setMaxUploadRetryTime(10000)
    }),
    preloadFunctions({
      firebaseApp: firebaseApp,
      setup: (functions) =>
        functions().useFunctionsEmulator('http://localhost:5001')
    })
  ])
}
