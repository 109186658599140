import React, { useEffect, useState } from 'react'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core'
import PaymentSteps from 'components/PaymentSteps'
import { OrderType } from 'types'
import { toMoneyFormat } from 'utilities/number'
import CLSX from 'clsx'
import { useQueryParams } from 'hooks/useQueryParams'
import { useFirestore } from 'reactfire'
import ErrorBlock from 'components/ErrorBlock'
import { ArrowBackIos } from '@material-ui/icons'

const useStyles = makeStyles(styles)
interface IProps {}
interface IBodyProps {
  order: OrderType
}

const MethodOnlineInfo: React.FC<IBodyProps> = ({ order, children }) => {
  if (order.paymentMethod !== 'ONLINE' && order.paymentMethod !== 'UNKNOWN')
    return null

  return (
    <Box>
      <Typography>
        Thông tin chi tiết về đơn hàng đã được gửi đến địa chỉ email{' '}
        <a href={`mailto:${order.buyerEmail}`}> {order.buyerEmail}</a>. Nếu
        không tìm thấy vui lòng kiểm tra trong hộp thư
        <b> Spam</b>
      </Typography>
    </Box>
  )
}

const MethodBankTransferInfo: React.FC<IBodyProps> = ({ order, children }) => {
  const classes = useStyles()
  if (order.paymentMethod !== 'BANK_TRANSFER') return null

  if (order.status === 'PAID')
    return (
      <Box>
        <Typography>
          Thông tin chi tiết về đơn hàng đã được gửi đến địa chỉ email{' '}
          <a href={`mailto:${order.buyerEmail}`}> {order.buyerEmail}</a>. Nếu
          không tìm thấy vui lòng kiểm tra trong hộp thư
          <b> Spam</b>
        </Typography>
      </Box>
    )

  return (
    <Box>
      <Box mb={2}>
        <Typography>
          Quý khách vui lòng chuyển khoản theo thông tin phía dưới để nhân viên
          Agilearn kiểm tra. Chúng tôi sẽ gửi email tới địa chỉ{' '}
          <b>{order.buyerEmail}</b> để thông báo quá trình đặt hàng thành công.
        </Typography>
      </Box>
      <Card elevation={0} className={classes.transferInfoCard}>
        <CardContent>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={8}>
              <Typography className={classes.whiteText}>
                CONG TY CO PHAN HOC VIEN AGILE
              </Typography>
              <Grid container>
                <Grid item xs={3}>
                  <Typography className={classes.whiteText} variant={'caption'}>
                    Ngân hàng
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.whiteText}
                  >
                    TP Bank
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.whiteText} variant={'caption'}>
                    Số TK
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.whiteText}
                  >
                    20200702702
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.whiteText} variant={'caption'}>
                    Số tiền
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.whiteText}
                  >
                    {toMoneyFormat(order.priceAfterDiscount)}đ
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Typography className={classes.whiteText} variant={'caption'}>
                    Nội dung
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.whiteText}
                  >
                    Thanh toán đơn hàng {order.orderCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.transferredButtonGrid}
              item
              sm={4}
              xs={12}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

const PaymentSuccessBody: React.FC<IBodyProps> = ({ order }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box display={'flex'} justifyContent={'center'} mt={5} mb={5}>
        <PaymentSteps currentStep={2} />
      </Box>

      <Container id={'order-detail'} maxWidth={'md'}>
        <Typography color={'secondary'}>
          Cảm ơn bạn đã mua hàng tại Agilearn!
        </Typography>

        <Box mt={2}>
          <Typography variant={'body2'}>Mã số đơn hàng của bạn:</Typography>
          <Box className={classes.orderCode}>
            <Typography variant={'h6'}>{order.orderCode}</Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant={'body2'}>Trạng thái thanh toán:</Typography>
          <Box
            className={CLSX({
              [classes.orderStatus]: true,
              [classes.wfpmStatus]: order.status === 'WAITING_FOR_PAYMENT',
              [classes.paidStatus]: order.status === 'PAID'
            })}
          >
            <Typography>
              {order.status === 'PAID' ? 'Đã thanh toán' : 'Chưa thanh toán'}
            </Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <MethodOnlineInfo order={order} />
          <MethodBankTransferInfo order={order} />
        </Box>

        <Box mt={2}>
          <Typography variant={'body2'}>
            Mọi thắc mắc vui lòng liên hệ:
          </Typography>
          <Box>
            <Typography>
              Địa chỉ email:{' '}
              <a href={`mailto:support@agilearn.vn`}>support@agilearn.vn</a>{' '}
              hoặc số điện thoại <a href={`tel:+842466608621`}>024-6660-8621</a>
            </Typography>
          </Box>
        </Box>

        <Box mt={6}>
          <a style={{ textDecoration: 'none' }} href={'https://agilearn.vn'}>
            <Button
              color={'primary'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIos fontSize={'small'} /> Về trang chủ
            </Button>
          </a>
        </Box>
      </Container>
    </Box>
  )
}

const useOrder = (orderCode: string) => {
  const db = useFirestore()
  const [order, setOrder] = useState<undefined | null | OrderType>(undefined)

  useEffect(() => {
    const query = db
      .collection('orders')
      .where('orderCode', '==', orderCode)
      .limit(1)
      
    query
      .get()
      .then((result) => {
        console.debug('get result')
        if (result && result.docs && result.docs[0]) {
          setOrder({
            id: result.docs[0].id,
            ...result.docs[0].data()
          } as OrderType)
        } else {
          setOrder(null)
        }
      })
      .catch((e) => {
        console.error(e.message)
        alert('Có lỗi xảy ra!')
      })
  }, [db, orderCode])

  return order
}

const PaymentSuccess: React.FC<IProps> = () => {
  const queryParams = useQueryParams()
  const orderCode = queryParams.get('order_code')
  const order = useOrder(orderCode || '')

  if (typeof order === 'undefined') return <LinearProgress />
  else if (!order) return <ErrorBlock variant={'404'} />

  return <PaymentSuccessBody order={order} />
}

export default PaymentSuccess
