import { createMuiTheme } from '@material-ui/core'
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    header: {
      height: number
      bg: string
      color: string
    }
    sidebar: {
      width: number
      bg: string
      fontColor: string
    }
    classroomSidebar: {
      width: number
    }
    customs: {
      color: {
        danger: string
        success: string
      }
      button: {
        minWidth: number
      }
    }
  }

  interface ThemeOptions {
    header: {
      height: number
      bg: string
      color: string
    }
    sidebar: {
      width?: number
      bg?: string
      fontColor?: string
    }
    classroomSidebar: {
      width: number
    }
    customs: {
      color: {
        success: string
        danger: string
      }
      button: {
        minWidth: number
      }
    }
  }
}

export default createMuiTheme({
  header: {
    height: 64,
    bg: '#fff',
    color: '#222b45'
  },
  sidebar: {
    width: 224,
    bg: '#091a7a',
    fontColor: '#fff'
  },
  palette: {
    primary: {
      light: '#6D59C5',
      main: '#443392',
      dark: '#32256A'
    },

    background: {
      default: '#fff'
    },
    text: {
      primary: '#222b45',
      secondary: '#66729c'
    }
  },
  typography: {
    fontFamily: '"Raleway", Arial, sans-serif',
    fontSize: 16
  },
  classroomSidebar: {
    width: 300
  },
  customs: {
    color: {
      success: '#007E33',
      danger: '#f50057'
    },
    button: {
      minWidth: 150
    }
  }
})
