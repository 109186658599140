import React from 'react'
import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

interface IProps {
  currentStep: number
}

const steps = ['Giỏ hàng', 'Thanh toán']

const PaymentSteps: React.FC<IProps> = (props) => {
  const { currentStep } = props
  const classes = useStyles()

  return (
    <Stepper className={classes.stepper} activeStep={currentStep}>
      {steps.map((label, index) => {
        return (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default PaymentSteps
