import { createStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    stepper: {
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })

export default styles
